import axios from "axios"


const apiUrl = process.env.REACT_APP_APIURL

export const statsGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/stats` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const leadsPost = async (collectData) =>{
    try{
        
        const response = await axios.post(`${apiUrl}/api/v1/contact` , collectData );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const settingsGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/settings` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}



export const clientGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/client` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const blogsGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/blog` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const mediaGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/media` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}





export const allpagesGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/allpages` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}




export const sliderGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/slider` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}



export const testmonialGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/testmonial` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}



export const achievementGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/achievement` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}


export const mediaCoverageGet = async () =>{
    try{
        
        const response = await axios.get(`${apiUrl}/api/v1/mediaCoverage` );
        return response.data;
    }
    catch(err){
        return  err.response.data;
    }
}
