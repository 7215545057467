export const localization: any = {
  en: {
    careers: "careers",
    home: "home",
    about_us: "about us",
    service: "service",
    our_client: "Our client",
    portfolio: "portfolio",
    contact_us: "contact us",
    products: "products",
    blogs: "blogs",
    about_description:
      "Dynamic Technosoft is a renowned leader in the IT services industry, with a track record of over a decade, delivering comprehensive software development and web solutions. Our commitment extends to clients in both the Nepali and international markets, as we endeavor to tackle complex challenges on a global scale. Our wealth of experience enables us to drive digital innovations that not only achieve exceptional business outcomes but also elevate performance and competitiveness for our valued customers and stakeholders",
    read_more: "Read more",
    about_dynamic: "About Dynamic",
    welcome_to_dynamic_technosoft: "Welcome to Dynamic Technosoft",
    welcome_to: "Welcome To",
    dynamic_technosoft: "Dynamic Technosoft",
    complete_school_management_system: "A Complete School Management system",
    dynamic_academic_erp: "Dynamic Academic ERP",
    dynamic_vision:
      "We transform and digitize your business providing solution to complex problems through technology.",
    free_demo: "Free demo",
    what_we: "What We ",
    offer_for_you: "Offer For You",
    school_college_erp: "School / College ERP",
    school_college_erp_description:
      "Dynamic Academic ERP is a remarkable tool for any educational institution. This software provides amazing facilities such as Academic Management, Account Management, GPS Tracking Management, HR & Payroll Management and much more. Our software is very helpful equipment for the school management committee and other stakeholders like teachers and parents. The software is perfect to make schools more safe and effective. With more than 500 clients, Academic ERP has proven itself to be very effective software for schools, colleges, and other academic institutions.",
    accounting_erp: "Accounting ERP",
    accounting_erp_description:
      "Dynamic Accounting Solution software is designed for Wholesalers, Dealers, Trading companies, Manufacturing companies, and Import/export companies. We have already gained trust among our valuable customers providing customized features of what exactly is necessary for the right operation of the business. Some of our valuable clients are Dabur Nepal, Hulas Steel, Dugar Group, Nissan Motors, Mahindra, John Deere, Godawari Steels, Royal Enfield, and so on. Our 500+ clients are using our product for day-to-day accounting activities and are successful in making the right business decisions.",
    hospital_erp: "Hospital Erp",
    hospital_erp_description:
      "Dynamic Hospital ERP (Hospital Enterprise Resource Planning) is an integrated technological software consist of easy-to-use modules that are designed to improve operational efficiencies, reduce inventory costs, labor costs, and better communication between departments within a hospital. This system helps in increasing speed in delivering health care services, both internally and externally. Patients record is properly recorded which assists to receive, store, and effectively operate all the necessary information concerning doctors, patients, and employees. Appointment scheduling, financial accounting, calendars, medical prescriptions, patient billing system, front desk management, and many more features are integrated into this ERP to support in making the operations efficient and saving huge time improving streamline healthcare processes through automation. This system manages the entire billing system with visibility on the accounting system of hospitals.",
    hr_and_payroll: "HR and Payroll",
    hr_and_payroll_decription:
      "Dynamic Infotech provides very powerful, user-friendly, and flexible Human Resource Management Software that helps organizations on the process of recruiting, managing, and directing people along with data management, record, and analysis for the smooth workflow. We are always passionate about finding out powerful tools and technologies for our software and developing crucial modules & features that have the power to keep business grow efficiently. Dynamic HRM software facilitates your organization with the structure and the ability to meet business needs by managing the company’s valuable resources i.e. employees. This software eliminates the use of spreadsheets and documents instead you will be able to manage employee’s data in one place. All the information is stored in a centralized database which the HR manager can easily retrieve at any time for various purposes.",
    sms_system: "SMS System",
    sms_system_description:
      "Mobile engagement and bulk SMS messaging have been trending for the past decade as we all know that mobile phones are ruling our digital world.Bulk SMS is one of the easiest and the most cost-effective marketing tools for businesses to keep a competitive edge over their competitors. It is a very useful and effective marketing channel for businesses to connect with their customers on a personal level.",
    sms_system_second_description:
      "At Dynamic Technosoft, we provide an effective and easy-to-use bulk SMS system for your business to enhance customer service with an impressive conversion rate. With this system, you can alert your customers about an update and promote the latest product offering.",
    sms_system_third_description:
      "Many of our clients have successfully implemented a bulk SMS system as a crucial part of their business activities and are happy in their success journey of the business maintaining customer relationship and generating more customers for their business. Now it’s your turn to use a highly useful cost-effective Bulk SMS system in your business and step into a competent digital world.",

    dynamic_asm: "Dynamic ASM",
    dynamic_asm_moduel_1_descripiton: "Use our centralized Contacts Management module to handle contacts for your company with ease. Maintain a record of member information, correspondence, and contact classification for tailored interaction. Easily manage the contacts in your company with our Contacts Management module, which includes features like:",
    dynamic_asm_moduel_2_descripiton: "With our Event Management module, you can easily organize and lead productive events. Effortlessly plan, coordinate, and carry out events—from making schedules to handling registrations and monitoring attendance. Examine our event management's features:",
    dynamic_asm_moduel_3_descripiton: "Use our Members Management module to foster a vibrant membership community. For more individualized member experiences, streamline the registration, renewal, and engagement tracking procedures. Examine the following member management features:",
    dynamic_asm_moduel_4_descripiton: "With our integrated SMS and Email module, you can help promote efficient communication. Send out announcements, newsletters, and customized messages straight from the system to keep your members informed. View the following features of our email and SMS management:",
    dynamic_asm_moduel_5_descripiton: "Use our Poll Management module to get insightful feedback. In order to make well-informed decisions based on community feedback, create, distribute, and analyze polls. Examine our poll management's features:",
    dynamic_asm_moduel_6_descripiton: "With our Fundraising Management module, you can easily plan, organize, and keep track of fundraising efforts. Using in-depth analytics, plan projects, monitor results, and maximize next efforts. Examine these aspects of our fundraising administration:",
    dynamic_asm_moduel_7_descripiton: "Use our Budget Management module to gain financial control over your company. To guarantee accountability and stability in finances, create, track, and evaluate budgets. Examine our budget management's features:",
    dynamic_asm_moduel_8_descripiton: "Use our AGM module to streamline the organization and conduct of annual general meetings. Oversee registrations, invitations, and paperwork to ensure a seamless and well-planned event. Examine our AGM management's features:",
    dynamic_asm_moduel_9_descripiton: "Use our Document Management module to arrange and safeguard your company's documents. For effective teamwork, centralize storage, manage access, and uphold version control. Examine our document management system's features:",
    dynamic_asm_moduel_10_descripiton: "Use our Accounting module to manage financial transactions with ease. Make sure that all aspects of financial management are accurate and transparent, from billing to tracking expenses and financial reporting. Explore our accounting management's features:",
    dynamic_asm_moduel_11_descripiton: "Use our Inventory module to maximize organizational inventory. For increased efficiency, keep an eye on stock levels, place fresh orders for supplies, and simplify inventory management procedures. Insurance: Use our Insurance module to manage insurance policies and claims. Easily manage policy details, keep track of renewals, and handle claims. Examine our inventory management's features:",
    dynamic_asm_moduel_12_descripiton: "With the help of our HRM and Payroll module, manage payroll and human resources procedures effectively. For improved HR operations, streamline payroll processing, attendance monitoring, and employee records. Examine the following features of our payroll and HRM:",
    dynamic_asm_moduel_13_descripiton: "Use our Website module to manage and integrate your company's online presence. Maintain a consistent and interesting website for your company, incorporate online forms, and keep content updated. Examine our website's management features:",



      Centralized_contact_database :  "Centralized contact database" ,
      Creation_and_management_of_Member_profile :  "Creation and management of Member profile" ,
      Contact_Segmentation :  "Contact Segmentation" ,
      Self_Registration :  "Self Registration" ,
      Online_Event_registration :  "Online Event registration" ,
      Attendee_Management :  "Attendee Management" ,
      Agenda_and_Schedule_Management :  "Agenda and Schedule Management" ,
      Event_Analytics_and_Reporting :  "Event Analytics and Reporting" ,
      Budgeting :  "Budgeting" ,
      Members_registration_details :  "Members registration details" ,
      New_membership :  "New membership" ,
      Centralized_Database :  "Centralized Database" ,
      Membership_Renewal :  "Membership Renewal" ,
      Membership_Levels :  "Membership Levels" ,
      Analytics_Reporting :  "Analytics & Reporting" ,
      Message_Composition_and_Customization :  "Message Composition and Customization" ,
      Member_Notifications :  "Member Notifications" ,
      Personalization_of_SMS :  "Personalization of SMS" ,
      Event_Notifications :  "Event Notifications" ,
      Automated_Reminders :  "Automated Reminders" ,
      Poll_creation :  "Poll creation" ,
      Question_Design :  "Question Design" ,
      Member_participation :  "Member participation" ,
      Multiple_voting_options :  "Multiple voting options" ,
      Online_voting :  "Online voting" ,
      Realtime_Results :  "Realtime-Results" ,
      Donation_type :  "Donation type" ,
      Donation_list :  "Donation list" ,
      Donation_Receipt :  "Donation Receipt" ,
      Confirmation_of_mail :  "Confirmation of mail" ,
      Send_reminder_to_members_for_contribution :  "Send reminder to members for contribution" ,
      Budget_Creation :  "Budget Creation" ,
      Budget_allocation :  "Budget allocation" ,
      Expenses_tracking_against_budget :  "Expenses tracking against budget" ,
      Financial_reporting :  "Financial reporting" ,
      AGM_planning :  "AGM planning" ,
      Member_registration :  "Member registration" ,
      Meeting :  "Meeting" ,
      Minutes_Management :  "Minutes Management" ,
      Online_voting_AGAIN :  "Online voting" ,
      Document_Repository :  "Document Repository" ,
      Document_Search :  "Document Search" ,
      Access_Controls_and_Permissions :  "Access Controls and Permissions" ,
      Document_Expiration_and_Alerts :  "Document Expiration and Alerts" ,
      Document_Analytics_and_Reporting :  "Document Analytics and Reporting" ,
      Ledger_and_ledger_group_creation :  "Ledger and ledger group creation" ,
      Receipts_and_payments :  "Receipts and payments" ,
      Reports :  "Reports" ,
      Journal_voucher_daybook_and_contra :  "Journal voucher, daybook and contra" ,
      Receipts_and_payments_AGAIN :  "Receipts and payments" ,
      Bank_reconciliation :  "Bank reconciliation" ,
      Product_management :  "Product management" ,
      Opening_stock :  "Opening stock" ,
      Purchase_and_sales :  "Purchase and sales" ,
      Supplier_management :  "Supplier management" ,
      Reports_AGAIN :  "Reports" ,
      Employee_Information_Management :  "Employee Information Management" ,
      Recruitment_and_Onboarding :  "Recruitment and Onboarding" ,
      Time_and_Attendance_Tracking :  "Time and Attendance Tracking" ,
      Payroll_Calculation_and_Processing :  "Payroll Calculation and Processing" ,
      Reporting_and_Analytics :  "Reporting and Analytics" ,
      Website_Creation_and_Design :  "Website Creation and Design" ,
      Content_Management :  "Content Management" ,
      Membership_Directory :  "Membership Directory" ,
      Event_Listing_and_Registration :  "Event Listing and Registration" ,
      Website_Analytics :  "Website Analytics" ,





    see_what_can: "See What Can ",
    we_do_for_you: "We Do For You",
    software_development: "Software Development",
    software_development_description:
      "With the combination of the experienced team and adopting the latest technology, Dynamic Infotech has been always dedicated and successful in developing efficient software products with high client satisfaction. We build software that doesn’t only accomplish customer requirements but also maintaining higher reliability and security of the data with the ability of remote access. Our ambitious and proactive team is always focused on integrating essential components into the system that provides accurate results along with proper data backup and recovery.",
    software_development_second_description:
      "As technology trends are changing rapidly and so does the level of our expertise and enthusiasm in the field. With more than 10 years of professional service in software development and 500+ happy customers using our product for day-to-day activities, it has inspired us to reach an even higher level. We are continuously chasing trends and new technologies to make sure we integrate such advance and powerful features in our software that increases more efficiency and reliability. As customer satisfaction is our main goal, we have an excellent commitment to providing instant customer support and help them solve their problem, which is the main story behind our success. We provide system flexibility and features based on your business requirements.",

    mobile_app_development: "Mobile app development",
    mobile_app_development_description:
      "Mobile Apps are becoming increasingly popular in Nepal since last decade among businesses, service providers, and users. Every sector from eCommerce and banking payments to food delivery and the health sector, the mobile app has proved itself as fundamental for effective communication with target customers. Mobile push notifications are a great way to reach out to the right people at the right time. At Dynamic Infotech, with a mission of digitizing the nation and work for its economic prosperity, we provide a responsive and robust mobile app that is user-friendly, cloud-powered, and equipped with real-time business integration.",
    mobile_app_development_second_description:
      "Dynamic Technosoft is a leading mobile application development company in Nepal that fabricate your ideas into action-driven, seamless, and customer satisfying mobile applications. With over 10 years of experience, we specialize in Android, iOS, and IoT application development and have already reinforced SMEs, startups, and large-scale enterprises. We are highly focused on enhancing business productivity with automation and proper security & privacy of data supported by a strong cloud-based backend infrastructure. We have our experienced developing team who will work coordinating closely with you to creatively contribute to building a mobile application that is powerful and efficient in terms of user engagement and revenue generation.",

    web_application_development: "Web Application development",
    web_application_development_description:
      "Web application offers the convenience of being accessible from any browser or device thus provides an app-like experience in the browser. In today’s world, by using web applications, businesses can now develop and become simpler and achieve its objective much faster. At Dynamic Infotech, we offer customized web application development that meets each and every business need and makes them competitive in the market. Our elite team of Web Application developers is highly skilled and efficient in delivering your business requirements and meeting your quality standard by complying with the predefined timelines. By implementing advanced tools and technologies, we develop a web application that helps businesses to target and reach potential and existing customers easily and quickly.",
    web_application_development_second_description:
      "With more than 10 years of experience in the information technology field, we are fully acknowledged with the benefit of technological support in increasing business productivity and growth. Thus, our highly focused and motivated developers are always ready for the collaboration to improve and ease your business performance by solving complex challenges working closely with you. We help in growing your business and maintain brand value by developing a system that maintains proper communication channels between potential customers and the business organization about the service or product provided by it.",
    domain_registration_web_hosting: "Domain registration & Web hosting",
    domain_registration_web_hosting_description:
      "Dynamic Technosoft provides domain registration and web hosting service that makes it possible for your business or website to be accessed by everyone on the web. As we all know, the website is a very crucial and inevitable part of this digital world for any business to sustain in the competitive market. We are here for you to provide a highly secured and flexible web hosting service with cloud-based data backup. As we are focused on improving your brand, we implement latest the SEO plug-ins to improve your website’s search engine rankings and establish a business identity.",
    domain_registration_web_hosting_second_description:
      "We keep on integrating all the latest tools and technologies into our service making it future proof. We are always passionate and dedicated to understanding our client’s business needs and helping them grow. For the efficient operation of your business, our collaborative customer support is available at any time in case you get any technical issues.",

    bulk_sms_service: "Bulk SMS Service",
    bulk_sms_service_description:
      "Mobile engagement and bulk SMS messaging have been trending for the past decade as we all know that mobile phones are ruling our digital world. Bulk SMS is one of the easiest and the most cost-effective marketing tools for businesses to keep a competitive edge over their competitors. It is a very useful and effective marketing channel for businesses to connect with their customers on a personal level.",
    bulk_sms_service_second_description:
      "At Dynamic Technosoft, we provide an effective and easy-to-use bulk SMS system for your business to enhance customer service with an impressive conversion rate. With this system, you can alert your customers about and update and promote the latest product offering. Many of our clients have successfully implemented a bulk SMS system as a crucial part of their business activities and are happy in their success journey of the business maintaining customer relationship and generating more customers for their business. Now it’s your turn to use a highly useful cost-effective Bulk SMS system in your business and step into a competent digital world.",
    our_latest_blog: "Our Latest Blog",
    our_latest_media: "Our Latest Media Coverage",
    our_latest: "Our Latest",
    blog: "Blogs",
    dynamic_blog: "Dynamic Blog",
    client: "Client",
    meet_our_client: "Meet Our Client",
    meet: "Meet",
    our_impressive_portfolio: "Our Impressive Portfolio",
    our_impressive: "Our Impressive",
    testimonial: "Testimonial",
    our_client_review: "Our Client's Review",
    our: "Our",
    client_review: "Client Review",
    our_team: "Our Team",
    team_member: "Team Member",
    our_expert_team: "Our Expert Team",
    our_company: "Our Company",
    we_run_all_kinds_of_it_services: "We run all kinds of IT services",
    about_company_description_first_paragraph:
      "Dynamic Technosoft is a recognized leader in the field of IT service providers, providing comprehensive software development and web products for more than a decade. With our services in the Nepali and international market Dynamic is trying to solve complex issues of its clients globally. Our years of experience help us develop digital innovations to deliver business outcomes, new levels of performance and competitiveness for our customers and their stakeholders.",
    about_company_description_second_paragraph:
      "One-stop-shop concept Dynamic makes it possible for our clients to outsource solutions for all of their needs conveniently. Our company's various services include ERP/ Customized Software Development, Mobile App Development, Website Development, Bulk SMS Solution, Domain Registration and Web Hosting, and Online attendance Solution. With 7 province dealers and 50+ dealer networks all over Nepal, we are able to create an extensive partner network that helps us drive collaboration and leverage technology independence.",
    about_company_description_third_paragraph:
      "Dynamic Vision is to constantly adapt and broaden our range of services to reflect the future needs of our customers. With our staff: Engineers, Software Developers, IT and Business Professionals, who have several years of experience in this field, we are able to find solutions for most of your company’s needs. This helps enterprises at any level maximize their profit. We are fortunate to have major corporate giants like Hulas Steel, Dugar Group, Nissan Motors and many more as our clients. They have been using our products and services to manage their companies well.",
    about_company_description_forth_paragraph:
      "But most importantly Dynamic Technosoft is so much more than just an IT service provider company. With our Dynamic vision, we aim to digitize Nepal and make it economically prosper. Our products like Academic ERP and Hospital ERP are helping many vital health and educational institutions provide the best of their services.",
    about_company_description_fifth_paragraph:
      "We as an IT service provider understand the need for technology to uplift any business. And we look forward to becoming your partner on the road to success.",
    dynamic_group: "Dynamic Group",
    our_mission: "Our Mission",
    our_mission_description:
      "Dynamic Technosoft has a clear and confident vision for the challenges of tomorrow. We aspire to support a culture of performance, matched with integrity. Our CLEAR Values help us constantly evolve and better ourselves.",
    our_mission_second_description:
      "We as a company are nothing without our clients. We work together with our clients to solve challenging issues to minimize business risk and maximize opportunity.",
    our_objective: "Our Objective",
    our_objective_description:
      "Dynamic Technosoft is so much more than just an IT service provider company.With our Dynamic vision, we aim to digitize Nepal and make it economically prosper.Our products like Academic ERP and Hospital ERP are helping many vital health and educational institutions provide the best of their services",
    our_vision: "Our Visions",
    dynamic_group_first_description:
      "Dynamic Technosoft Pvt. Ltd is one of seven companies under Dynamic Group. With a focus on IT and modern-day skills, all these companies have been trying to uplift societies on the whole. Dynamic Skills Pvt. Ltd, Dynamic Media Networks & Communication Pvt. Ltd., Gyan Mandir Academy Pvt. Ltd., Dynamic Enterprises are the companies in the group that works to improve the education system, transfer world-class tech knowledge to the younger generation, and get more youths trained in the field of 21st-century skills.",
    dynamic_group_second_description:
      "Whereas the other companies like Dynamic Technosoft Pvt. Ltd., Brainlight Technologies Pvt. Ltd., Pivotal Technologies, Codeplex IT Enterprises are responsible to provide solutions to complex problems through technology. The software and products provided by this element of the group are helping numerous companies to improve their services and profits.",
    dynamic_group_third_description:
      "With more than 150 employees who share common norms and values, Dynamic Group is one of the strongest institutions in the nation. Serving its clients and stakeholders the best.",
    get_in_touch: "Get in touch",
    ready_to_get_started: "Ready to get started ?",
    our_product: "Our Product",
    dynamic_academic_erp_description:
      "Dynamic Academic ERP is a remarkable tool for any educational institution. This software provides amazing facilities such as Academic Management, Account Management, GPS Tracking Management, HR & Payroll Management and much more. Our software is very helpful equipment for the school management committee and other stakeholders like teachers and parents. The software is perfect to make schools more safe and effective. With more than 500 clients, Academic ERP has proven itself to be very effective software for schools, colleges, and other academic institutions.",
    academic_management: "Academic Management",
    academic_management_decription:
      "Build your school digitally and systematically take control of everything with a single platform from anywhere.",
    multiple_ledgers: "Multiple ledgers",
    bank_reconciliation: "Bank reconciliation",
    receipt_and_payment: "Receipt & Pyment",
    interest_calculation: "Interest calculation",
    trial_balance: "Trial Balance",
    profit_and_loss: "Profit & loss",
    balance_sheet: "Balance sheet",
    cash_flow_and_fund_flow: "Cash flow & fund flow",
    ratio_analysis: "Ratio Analysis",

    exam_management: "Exam Management",
    exam_management_description:
      "Reduce workload for school staff as examination times are the busiest for each school.Teachers can enter the student’s marks through mobile apps whereas students & parents can view & download results.",
    exam_schedule: "Exam Schedule",
    generate_admit_card: "Generate Admit card",
    mark_entry_and_result_summary: "Mark Entry & Result Summary",
    mark_sheet_and_reports: "Mark sheets, Certificates, and Reports",
    instant_alert_and_notification_to_parents:
      "Instant Alert & Notification to parents",

    fee_management: "Fee Management",
    fee_management_description:
      "Safest and secure online fee collection & payment portal via the best payment gateways such as ESewa and Khalti.",

    generate_fee_item: "Generate Fee Item",
    manage_fee_item: "Manage Fee Discount",
    online_fee_payment: "Online Fee Payment",
    fee_receipt: "Fee Receipt",
    bill_generate_and_bill_print: "Bill Generate & Bill Print",
    transfer_fee_receipt_bank_acc: "Transfer Fee Receipt Bank Acc.",
    income_statement: "Income Statement",
    reminder_slip: "Reminder Slip",
    mobile_notification_to_parents: " Mobile notification to parents",

    library_management: "Library Management",
    library_management_description:
      "Efficiently organize and maintain the book details present in the library with easy book borrow and track the book’s status.",
    elibrary_and_book_order: "E-Library & Book Order",
    complete_track_of_book: "Complete Track of Book",
    library_fine_ereceipt: "Library fine e-receipt",
    online_book_renewal: "Online Book renewal",
    transaction_and_return_report: "Transaction & return report",

    master_library :  "Master Library",
    book_entry :  "Book Entry",
    print_barcode :  "Print Barcode",
    library_membership :  "Library Membership",
    library_card :  "Library Card",
    book_issue :  "Book Issue",
    book_receive :  "Book Receive",



    transportation_management: "Transportation Management",
    transportation_management_decription:
      "Manage bus schedules and collect transportation fees conveniently. High security for students & bus drivers with GPS tracking and navigation.",
    route_schedule: "Route Schedule",
    gps_tracking_of_vehicle: "GPS tracking of vehicle",
    manage_transport_expenses: "Manage transport expenses",
    vehicle_profile_manager: "Vehicle profile manager",
    instant_sms_alerts: "Instant SMS alerts",
    student_maping: "Student mapping",

    accounting_system: "Accounting System",
    accounting_system_description:
      "Accurately and efficiently manage journal, ledger, trial balance, balance sheet, cash flow, & audit reports. Accelerate the financial operation of the institution.",
    cash_payments_and_receipts: "Cash payments & receipts",
    journal_ledger: "Journal Ledger ",
    trial_balance_and_sheet: "Trial balance & Balance sheet",
    income_and_expenditure: "Income & Expenditure",
    audit_reports: "Audit reports",

    sms_management: "SMS Management",
    sms_management_description:
      "Always keep parents connected and informed about their children’s progress. Send instant SMS notification on holidays, exams, fee reminders, events, and so on.",
    fee_alerts: "Fee Alerts",
    result_alerts: "Result Alerts",
    news_information_alerts: "News/ Information alerts",
    holiday_information: "Holiday information",
    urgent_alert_to_parents: "Urgent alert to parents / students",

    user_management: "User Management",
    user_management_description:
      "Provide unique login credentials for each user maintaining the utmost security. Grant access to various functionalities/modules according to users.",
    username_and_password_generation: "Username & Password generation",
    set_users_access_level_control: "Set users access level control",
    grant_special_permission: "Grant special permission",
    edit_user_details_and_reset_password: "Edit user details & reset password",
    block_any_user: "Block any user",

    hr_and_payroll_management: "HR & Payroll Management",
    hr_and_payroll_management_description:
      "Maintain employees’ profile, attendance, salary, deduction, leaves, etc. in a single platform.",
    employee_profile_management: "Employee profile management",
    salary_calculation_automation: "Salary calculation automation",
    employee_summary_and_reports: "Employee summary and reports",
    load_interest_management: "Loan/Interest management",
    account_module_integration: "Account module integration",

    accounting_solution: "Accounting Solution",
    accounting_solution_description:
      "It allows you to completely manage and keep records of the school inventory. Get stock reports of school, product inspection, and keep records of purchase order management.",
    accounting_solution_second_description:
      "Dynamic Accounting Solution software is designed for Wholesalers, Dealers, Trading companies, Manufacturing companies, and Import/export companies. We have already gained trust among our valuable customers providing customized features of what exactly is necessary for the right operation of the business. Some of our valuable clients are Dabur Nepal, Hulas Steel, Dugar Group, Nissan Motors, Mahindra, John Deere, Godawari Steels, Royal Enfield, and so on. Our 500+ clients are using our product for day-to-day accounting activities and are successful in making the right business decisions.",
    product_management: "Product management",
    opening_stock: "Opening stock",
    purchase: "Purchase (Quotation, order, invoice & return)",
    supplier_management: "Supplier Management",

    hostel_management: "Hostel Management",
    hostel_management_description:
      "Manage & administrate all the necessary details of the school’s hostel efficiently. Easy student allocation and keeps hostel information organized along with managing hostel records and paying hostel bills.",
    boarder_student_details: "Boarder’s Student details",
    boarders_type: "Boarders Type",
    manage_room_details: "Manage room details",
    allocate_rooms_to_students: "Allocate rooms to students",
    students_fee_collection: "Students’ Fees Collection",

    hospital_enterprise_resource_planning:
      "Hospital Enterprise Resource Planning",

    account_management: "Accounts Management",
    account_management_description:
      "Elevate your financial management with our software, which allows you to assess your business's financial health accurately. Generate essential reports, including journals, profit and loss statements, balance sheets, fund flow statements, cash flow reports, and budgets. These insights empower you to make informed decisions, ensuring the financial stability and growth of your business. Trust us to optimize your financial management with robust reporting capabilities.",
    cheque_book: "Cheque Book",
    day_book_and_pending_voucher: "Day book & pending voucher",

    inventory_management: "Inventory Management",
    inventory_management_description:
      "Transform your business's supply chain management with our comprehensive solution. Our system simplifies the tracking, management, and organization of goods and production processes, resulting in minimized waste and informed investment decisions. Experience the benefits of enhanced supply chain efficiency and improved overall business operations.",
    product_group_or_product_category: "Product Group/Product Category",
    go_down: "Godown",
    purchase_or_sales_quotation: "Purchase/Sales quotation & order",
    purchase_or_sales_invoice: "Purchase/Sales Invoice & return",
    stock_transfer_or_stock_journal: "Stock Transfer/Stock Journal",
    purchase_anlysis: " purchase analysis",

    sms_management_hospital: "SMS Management",
    sms_management_hospital_description:
      "Forge deeper connections with your customers and elevate your customer service to new heights, all while achieving impressive conversion rates. Keep your customers informed about product updates and the latest offerings with our efficient Bulk SMS service. Our Bulk SMS service is designed to enhance customer engagement and ensure your brand stays top-of-mind. Experience the power of direct communication and boost customer satisfaction with our SMS management solutions.",

    flexible_system: "Flexible system",
    improve_the_level_of_professionalism:
      "Improve the level of professionalism",
    high_roi_and_low_cost: "High ROI and low cost",
    scheduling_customized_sms: "Scheduling customized SMS",
    customer_support: "24x7 customer support",

    account_management_accounting_description:
      "Understand the financial health of your business by preparing reports like a journal, P/L statements, balance sheet, fund flow, cash flow, and budgets.",
    payment_and_receipt: "Payment & Receipt",
    cost_center: "Cost Center",
    journal_and_contra: "Journal & Contra",
    cash_or_bank_books: "Cash/Bank Books",
    day_book: "Day Book",

    time_attendance_system: "Time Attendance System",
    time_attendance_system_description:
      "With an accurate time tracking, you can prevent payroll errors and provide the accurate payroll process. It helps you to stay compliance with regulations especially in overtime.",
    electronic_attendance_processing: "Electronic Attendance Processing",
    over_time_or_under: "Over Time/under time Calculation",
    dynamic_shift_management: "Dynamic Shift Management",
    online_request_or_approval: "Online Request/Approval",

    payroll_management_system: "Payroll Management System",
    payroll_management_system_description:
      "Reduces possible errors during the performance of the payroll system and enhances the system’s performance. Eases the process of calculating wages, finding taxes, paying taxes to the government, and keeping financial records such as bonuses, deductions, and net pay.",
    timely_and_accurate_salary_calculation:
      "Timely and Accurate Salary Calculation",
    wage_calculation: "Wage Calculation",
    bonus_nad_ot_calculation: "Bonus and OT  Calculation",
    late_or_early_deduction_calculation: "Late/Early Deduction Calculation",
    salary_sheet: "Salary Sheet",

    pis_hr: "Personal Information System HR",
    pis_hr_description:
      "Enabling the HR department to spend less time on clerical tasks, ensuring the accuracy of employee data, and empowering employees to take a greater role in the management of their information are crucial objectives in modern HR management.",

    staff_appraisal_system: "Staff Appraisal System",
    staff_appraisal_system_description:
      "In today's dynamic business landscape, staff appraisal systems are essential tools for ensuring that teams and individuals are aligned with their organizational ambitions and goals. These systems play a critical role in boosting overall productivity while maintaining high levels of employee motivation and satisfaction.",
    user_definable_performance_standard: "User Definable Performance Standard",
    user_definable_performance_rating: "User Definable Performance Rating",
    automatic_job_post: "Automatic Job Post",
    view_real_time_application_data: "View Real Time Application Data",
    management: "Management",

    web_sms_system: "Web SMS System",

    services: "Services",
    trust_and_client_focus: "Trust and Client Focous",
    benefits_of_software_development: "Benefits of Software development ",
    software_maintence: "Software Maintenance",
    payment_gateways_integration: "Payment Gateways Integration",
    user_friendly_portability: "User-friendly and Portability",
    high_data_security_and_reliability: "High data security and reliability",
    custom_software_development: "Custom software development",

    seamless_connectivity: "Seamless connectivity",
    customer_centric_approach: "Customer centric approach",
    accelerate_online_activities: "Accelerate online activities",
    build_brand_awareness: "Build brand awareness",
    enhanced_customer_support: "Enhanced Customer support",
    const_saving: "Cost saving",

    strong_security_and_data_management: "Strong security and Data Management",
    flexible_and_reliable_web_hosting: "Flexible & Reliable web hosting",
    email_and_domain: "Email and Domain",
    secured_and_powerful_servers: "Secured and Powerful servers",
    cloud_data_backup_and_recovery: "Cloud Data Backup & Recovery",
    academic_erp_dashboard: "Academic ERP Dashboard",
    web_development: "Web Development",
    features: "Features",
    we_provide: "We Provides",
    our_bulk_service_provides: "Our Bulk SMS service provides",
    client_focus: "Client Focus",
    client_focus_description:
      "Our success relies on the satisfaction of our customers. We are dedicated to delivering exceptional service and value.",
    leadership: "Leadership",
    leadership_description:
      "Our services allow us to lead a digital evolution, solving major challenges in our communities and companies.",
    effective: "Effictive",
    effective_description:
      "We are one hundred percent committed to providing effective solutions to the challenges of our clients.",
    aspiration: "Aspiration",
    aspiration_description:
      "We aspire to keep on improving our quality of service and grow strong as a company collectively.",
    results: "Results",
    results_description:
      "We are responsible for our commitments and accountable as well for the expected results.",
    our_location: "Our Locations",
    head_office: "Head Office",
    corporate_office: "Corporate Office",
    butwal_branch: "Butwal Branch",
    resources : "Resources",
    media : "Media",
    class_setup : "Class setup",
    add_student : "Add student",
    student_remarks : "Student remarks",
    subject_setup : "Subject setup",
    class_time_table : "Class time table",
    lesson_plan : "Lesson plan",
    report : "Report",


    streamlined_record_keeping : "Streamlined Record-Keeping",
    invoice_automation : "Invoice Automation",
    expense_management : "Expense Management",
    bBudget_control : "Budget Control",
    robust_financial_reporting : "Robust Financial Reporting",
    tax_Compliance_Made_Easy : "Tax Compliance Made Easy",
    detailed_Audit_Trails : "Detailed Audit Trails",

    Streamlining_HR_Operations : "Streamlining HR Operations",
    Enhancing_Data_Accuracy : "Enhancing Data Accuracy",
    Employee_Empowerment : "Employee Empowerment",


    Enhanced_Customer_Service : "Enhanced Customer Service",
    Impressive_Conversion_Rates : "Impressive Conversion Rates",
    Timely_Updates : "Timely Updates",
    Cost_Effective : "Cost-Effective",
    Customer_Relationship_Building : "Customer Relationship Building",
    Competitive_Edge : "Competitive Edge",


  },
};

class LocalizationHelper {
  langauge = "en";
  setLangauge(langauge: any) {
    this.langauge = langauge;
  }
  getString(key: string) {
    return localization[this.langauge][key] ?? "Untranslated";
  }
}
export const strings = new LocalizationHelper();
