import { Col, Container, Row } from "react-bootstrap";
import { CoverImage } from "../helper/CoverImage";
import development from "../../Assets/coverImage/development.png";
import { PortFolio } from "./Portfolio";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { Fade, Rotate } from "react-reveal";
import { Title } from "../../Styles/StyleComponent";

export const PortfolioPages = () => {
  return (
    <>
      {/* <CoverImage image={development} path="portfolio" /> */}
 <BackgroundContainer title={'Portfolio'} description={''} > 
      <Container className="py-5">

      <Col xs={12}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Portfolio</span></Title>
      </Fade>
      </Col>
      </Row>
    </Col>

        <PortFolio />
      </Container>

      </BackgroundContainer>
    </>
  );
};
