import logo from '../../Assets/logo.svg'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { strings } from '../../Api/Localization';
import { NavLink, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { GlobalData } from '../../contextapi/ContextProvider';
import { Accordion, Button, Offcanvas } from 'react-bootstrap';

function BasicExample() {
  let {mobile,mobilelg} = useContext(GlobalData);
  const location = useLocation();
 const menuItems = [
    {
      label: strings.getString("home"),
      path: "/",
    },
    {
      label: strings.getString("about_us"),
      path: "/about-us",
      subMenu: [
        {
          label: strings.getString("our_team"),
          path: "/about-us/our-team",
        },
        {
          label: strings.getString("our_company"),
          path: "/about-us/our-company",
        },
        {
          label: strings.getString("dynamic_group"),
          path: "/about-us/dynamic-group",
        },
      ],
    },
    {
      label: strings.getString("products"),
      path: "/products",
      subMenu: [
        {
          label: strings.getString("dynamic_academic_erp"),
          path: "/products/academic-erp",
        },
        {
          label: strings.getString("hospital_erp"),
          path: "/products/hospital-erp",
        },
        {
          label: strings.getString("accounting_solution"),
          path: "/products/accounting-solution",
        },
        {
          label: strings.getString("hr_and_payroll"),
          path: "/products/hr-and-payroll-management",
        },
        {
          label: strings.getString("sms_system"),
          path: "/products/web-sms",
        },
        {
          label: strings.getString("dynamic_asm"),
          path: "/products/dynamic-asm",
        },
      ],
    },
    {
      label: strings.getString("service"),
      path: "/services",
      subMenu: [
        {
          label: strings.getString("software_development"),
          path: "/services/software-development",
        },
        {
          label: strings.getString("mobile_app_development"),
          path: "/services/mobile-app-development",
        },
        {
          label: strings.getString("web_application_development"),
          path: "/services/web-application-development",
        },
        {
          label: strings.getString("bulk_sms_service"),
          path: "/services/bulk-sms-service",
        },
        {
          label: strings.getString("domain_registration_web_hosting"),
          path: "/services/domain-registration-web-hosting",
        },
      ],
    },
    // Add more menu items as needed
    {
      label: "Resources",
      path: "/resources",
      subMenu: [
        {
          label: "Tutorials",
          path: "/resources/tutorials",
        },
        {
          label: "Blogs",
          path: "/resources/blogs",
        },
        {
          label: "Testimonials",
          path: "/resources/testimonials",
        },
        {
          label: "Media Coverage",
          path: "/resources/media-coverage",
        },
      ],
    },
    {
      label: strings.getString("our_client"),
      path: "/our-client",
    },
    {
      label: strings.getString("contact_us"),
      path: "/contact-us",
    },

  ];


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80 && !scrolled) {
        setScrolled(true);
      } else if (window.scrollY <= 80 && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);


  
  return (
    <div className={`box navbar-wrapper sticky-top ${scrolled && !mobilelg ? 'bg-white scroll-navbar' : ''}`} style={{
      height : 80,
      background: mobilelg ?  "rgba(0, 130, 198, 1)" : location.pathname === "/" ? "rgba(0, 130, 198, 1)" : "",
       boxShadow: scrolled ? "rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 6px" : '' , transition : "0.1s ease-in-out"
    }}>
    <Navbar expand="lg" className="bg-body-tertiary py-0 h-100 ">
      <Container className='py-0'>
        <Navbar.Brand>
        <NavLink to={'/'}>
         {mobilelg ? 
          <img src={logo} alt="" style={{userSelect : "none", height : 40, filter: "brightness(0) invert(1)" }} /> :
         <img src={logo} alt="" style={{userSelect : "none", height : 40, filter: !scrolled && location.pathname === "/" ?  "brightness(0) invert(1)" : 'none'}} />}
        </NavLink>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}

        <Button  className={`d-lg-none ms-3 ${scrolled ? '' : ''} bg-transparent border-0 `} onClick={handleShow}>
        <svg className="" height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 17 14">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
  </svg>
      </Button>


        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ms-auto" >
            <ul className="list-unstyled d-flex text-capitalize navlist"  > 

              {
                menuItems?.map((val,index)=>{
                    return <>
                    {
                        val.subMenu?.length ? 
                        <>
                        <li key={index} className="ms-xl-5 navitem">
                  
                    <NavLink to={val.path}  className='navlink'>
                   
                        {val.label}
                    </NavLink>

                    <ul className="list-unstyled  subnavlist card">
                        
                    {
                        val.subMenu?.map((sval,sindex)=>
                            <li key={sindex} className="subnavitem">
                      
                    <NavLink to={sval.path} className='subnavlink'>
                
                        {sval.label}
                    </NavLink>
                </li>
                        )
                    }

                    </ul>
                </li>
                        </>
                        :

                        <li key={index} className="ms-xl-5 navitem">
                     
                    <NavLink to={val.path}  className='navlink'>
                 
                        {val.label}
                    </NavLink>
                </li>
                    }
                    </>
                })
                
            
            }


              
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


    <Offcanvas show={show} onHide={handleClose}  >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='mobile-nav-body'>


        <Accordion>

        {
            menuItems.map((val,index)=>{
              return(<>

              {
                val?.subMenu?.length ? 

                <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header className=''>{val.label}</Accordion.Header>
                <Accordion.Body>
                  {
                    val.subMenu?.map((sval,sindex)=>{
                      return(<>
                      <div className="link-box" onClick={handleClose} key={sindex}>
              <NavLink to={sval.path} className='mobile-link' >
                  {sval.label}
              </NavLink>
             </div>
             </>)
                    })
                  }
                </Accordion.Body>
              </Accordion.Item>

              : 


              <div className="accordion-item" key={index}  onClick={handleClose}>
                          <h2 className="accordion-header">
                          <NavLink to={val.path} className='mobile-link single-button'>
                          {val.label}
              </NavLink>
                          </h2>
                        </div>


         
              }
               
              </>)
            })
          }


     

    </Accordion>
        {/* <ul className="list-unstyled align-items-center my-auto justify-content-end w-100">
          {
            menuItems.map((val,index)=>{
              return(<>
                <li key={index} className='mx-xl-3 mx-lg-2 py-3 border-bottom' onClick={handleClose}>
                  <NavLink to={val.path} className={`text-decoration-none text-black fs-28-600`} >{val.label}</NavLink>
                </li>
              </>)
            })
          }
        </ul> */}
        </Offcanvas.Body>
      </Offcanvas>


    </div>
  );
}

export default BasicExample;
