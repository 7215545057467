import { CoverImage } from "../helper/CoverImage";
import career from "../../Assets/coverImage/career.jpg";
import { Col, Container, Row } from "react-bootstrap";
import { Button, SubTitle, Title } from "../../Styles/StyleComponent";
import { LocationIcon } from "../../Assets/SocialMediaIcon";
import { useState } from "react";
import { ApplyForm } from "./ApplyForm";
import { Fade } from "react-reveal";
import BackgroundContainer from "../../utils/BackgroundContainer";

export const Careers = () => {
  const [show, setShow] = useState<string | number>("");
  const [showForm, setShowForm] = useState<boolean>(false);

  const vaccancy = [
    {
      id: 1,
      title: "Sales and Marketing Executive",
      jobDescription:
        "•Conduct market research to identify selling possibilities and evaluate customer needs • Actively seek out new sales opportunities through client visiting, cold calling, networking and social media Set up meetings with potential clients and listen to their wishes and concerns.• Sells products by establishing contact and developing relationships with prospects• Prepare and deliver appropriate presentations on products and services• Participate on behalf of the company in exhibitions or conferences (if needed)• Able to Negotiate/close deals and handle complaints or objections• Collaborate with team members to achieve better results• Gather feedback from customers or prospects and share with internal teams• Maintains relationships with clients by providing support, information, and guidance• Prepare reports by collecting, analysing, and summarising information.• Maintain quality service by establishing and enforcing organisation standards.• Maintain a Strong Knowledge of offered Products and Services• Documenting plans, activities and opportunities accurately in the CRM system.",
      numberOfVaccancy: "12",
      qualifications:
        "• Should be Bsc IT/BCA/BIM/BBS/BBA• Experience in any accounting background will be an added advantage.• Having a driving license and personal vehicle.• Good knowledge of Ms-word/ Ms-Excel/Ms-Powerpoint• Proficiency in English & Nepali• Hands-on experience with CRM software is a plus• Thorough understanding of marketing and negotiating techniques• Fast learner and passion for marketing• Self-motivated with a results-driven approach• Aptitude in delivering attractive presentations• Presentation Skills• Negotiation Skills• Target Achievement Skills• Creativity• Perform well under deadlines and be detail-oriented• Sales Planning Skills• Independence• Motivation for Sales Skills",
      deadLine: "",
      location: "Kathmandu",
      experience: "Freshers Can Apply",
    },
    {
      id: 2,
      title: "Laravel Developer",
      jobDescription:
        "•Discussing project aims with the client and development team•Designing and building web applications using Laravel•Document the development process, architecture, and standard components •Lead the entire web application development life cycle right from concept stage to delivery and post launch support •Troubleshooting issues in the implementation and debug builds. •Working with front-end and back-end developers on projects. •Testing functionality for users and the backend. •Ensuring that integrations run smoothly. •Scaling projects based on client feedback. •Recording and reporting on work done in Laravel. •Maintaining web-based applications. •Discussing project aims with the client and development team ",
      numberOfVaccancy: "2",
      qualifications:
        "•A degree in programming, computer science, or a related field. •In depth knowledge of object-oriented PHP and Laravel 5 PHP Framework •Knowledge of database design and querying using SQL. •Proficiency in HTML and JavaScript. •Practical experience using the MVC architecture. •Software testing (PHPUnit, PHPSpec, Behat) •Problem-solving skills and critical mindset. ",
      deadLine: "",
      location: "Kathmandu/Birgunj",
      experience: "Mid-Level",
    },
  ];

  return (
    <>
      {/* <CoverImage image={career} path="careers" /> */}

      <BackgroundContainer title={``} description={''}>

      <Container className="py-5">
        <Row>
          <Col >
          <div className="mb-4">
          <Fade up delay={0}>
            <SubTitle>Careers</SubTitle>
          </Fade>
          <Fade up delay={100}>
            <Title>We are Hiring !!!</Title>
          </Fade>
        </div>
          </Col>
          </Row>

        {vaccancy.map((v) => (
          <div className="mb-4" key={v.id}>
            <div
              style={{
                background: "#e5f2f9",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              className="p-3"
            >
              <Row
                onClick={() => {
                  if (show === "" || show !== v.id) {
                    setShow(v.id);
                    setShowForm(false);
                  } else {
                    setShow("");
                    setShowForm(false);
                  }
                }}
                className="gy-4 align-items-center"
              >
                <Col sm={9}>
                  <Row className="d-flex align-items-center ">
                    <Col style={{ maxWidth: "75px" }}>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "40px",
                          height: "35px",
                          borderRadius: "4px",
                          background: "#0c253f",
                          paddingBottom: "4px",
                        }}
                      >
                        {v.id !== show ? (
                          <span style={{ color: "#fff", fontSize: "28px" }}>
                            ＋
                          </span>
                        ) : (
                          <span style={{ color: "#fff", fontSize: "28px" }}>
                            ‒
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <h4
                          style={{
                            color: "#0c253f",
                          }}
                          className="fs-5 m-0"
                        >
                          {v.title} - {v.numberOfVaccancy} Posts -
                          <span className="fs-6"> {v.experience}</span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {show === v.id ? (
                  <Col
                    sm={3}
                    className="d-flex align-items-center justify-content-md-end justify-content-start"
                  >
                    <LocationIcon color="#000" />{" "}
                    <h6 className="m-0 ms-2">{v.location}</h6>
                  </Col>
                ) : (
                  <Col
                    sm={3}
                    className="d-flex align-items-center justify-content-md-end justify-content-start"
                  >
                    <Button className="m-0">Apply Now</Button>
                  </Col>
                )}
              </Row>
            </div>

            {show === v.id && !showForm && (
              <div>
                <Row className="py-4 gy-4">
                  <Col lg={6}>
                    <h6 style={{ color: "#004163" }} className="sub-title p-0">
                      Job Description :
                    </h6>
                    <div style={{ color: "#005b8a" }}>
                      <ul>
                        {v.jobDescription
                          .split("•" || ",")
                          .slice(1)
                          .map((ls, i) => {
                            return (
                              <li style={{ marginTop: "4px" }} key={i}>
                                <span
                                  style={{
                                    color: "#1f5d9e",
                                    marginRight: "8px",
                                  }}
                                >
                                  ☑
                                </span>
                                {ls}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{ color: "#004163" }} className="sub-title p-0">
                      Qualification :
                    </h6>
                    <div style={{ color: "#005b8a" }}>
                      <ul>
                        {v.qualifications
                          .split("•" || ",")
                          .slice(1)
                          .map((ls, i) => {
                            return (
                              <li style={{ marginTop: "4px" }} key={i}>
                                <span
                                  style={{
                                    color: "#1f5d9e",
                                    marginRight: "8px",
                                  }}
                                >
                                  ☑
                                </span>
                                {ls}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-center justify-content-end">
                    <Button className="m-0" onClick={() => setShowForm(true)}>
                      Apply Now
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            {show === v.id && showForm && <ApplyForm />}
          </div>
        ))}
        <p style={{ color: "#1f5d9e", fontWeight: "500" }}>
          Interested candidates, please drop your resume at: hr@dynamic.net.np
        </p>
      </Container>

      </BackgroundContainer>
    </>
  );
};
