import { Row } from "react-bootstrap";
import {
  ColCards,
  EllipsisText,
  SubTitleText,
  PortfolioBox,
} from "../../Styles/StyleComponent";
import navkar from "../../Assets/portfolio/navakar.png";
import nyef from "../../Assets/portfolio/nyef.png";
import sana from "../../Assets/portfolio/sana.png";
import vodka from "../../Assets/portfolio/vodka.png";

const CustomCol = ({ img, name }: { img: any; name: string }) => (
  <ColCards sm={6} md={6} lg={4}>
    <img
      src={img}
      alt=""
      width="95%"
      height="400px"
      style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"  }}
    />
    <PortfolioBox>
      <div>
        <SubTitleText className="text-start" color="#0D97D6">
          WebSite
        </SubTitleText>
        <EllipsisText className="text-start" fontSize="22px" fontWeight="700">
          {name}
        </EllipsisText>
      </div>
    </PortfolioBox>
  </ColCards>
);

export const PortFolio = () => {
  const projects = [
    {
      name: "Navkar Group",
      img: navkar,
    },
    {
      name: "Polska Vodka",
      img: vodka,
    },
    {
      name: "Nepalese Young Entrepreneurs' Forum",
      img: nyef,
    },
    {
      name: "L.S. Neuro and General Hospital Pvt. Ltd.",
      img: sana,
    },
    // {
    //   name: "Health office, Kanchanpur",
    //   img: health,
    // },
    // {
    //   name: "Little Buddha Education Foundation",
    //   img: lilbuddha,
    // },
  ];
  return (
    <div className="mt-5 mb-4">
      <Row className="g-lg-5 g-md-4 gy-4">
        {projects.map((p, i) => (
          <CustomCol img={p.img} name={p.name} key={i} />
        ))}
      </Row>
    </div>
  );
};
